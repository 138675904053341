<template>
  <div class="updateLog">
    <el-row>
      <div class="content">
        <div class="content_item" v-for="(version, index) in versions" :key="index">
          <div class="content_title">
            {{ version.version }} <span v-if="version.time">{{ version.time }}</span>
          </div>
          <div class="content_main">
            <div v-if="version.newFeatures && version.newFeatures.length > 0">
              <p>新增</p>
              <p v-for="(feature, index) in version.newFeatures" :key="index">{{ feature }}</p>
            </div>
            <div v-if="version.fixFeatures && version.fixFeatures.length > 0">
              <p>修复</p>
              <p v-for="(feature, index) in version.fixFeatures" :key="index">{{ feature }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Update",
  data() {
    return {
      versions: [
        {
          version: "v1.0.0",
          newFeatures: ["1、已有功能优化"],
          fixFeatures: ["1、修复已知bug"]
        },
      ]
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.updateLog {
  padding: 40px 25% 60px;
  min-height: 750px;
  background: #F7F8FA;
  color: #252525;
  .content_item {
    padding: 16px 24px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d9dce6;
    margin-bottom: 12px;
    .content_title {
      font-size: 24px;
      font-weight: bold;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .content_main {
      color: #252525;
      font-size: 14px;
    }
  }
}
</style>
